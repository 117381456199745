export function getArray<T>(values: T | T[]): T[] {
    if (values === undefined) {
        return [];
    }

    if (Array.isArray(values)) {
        return values || [];
    }

    return [values];
}

export function mergeArrays<T>(baseValues: T[], newValues: T[]): T[] {
    const baseArray = getArray(baseValues);
    const newArray = getArray(newValues);

    return [...baseArray, ...newArray];
}
